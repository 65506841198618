import {
  getParamsOptionsAsync,
  getCommonParamsOptions
} from '@/utils/common.js'
export const baseNewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'user.tenant-name',
      otherOptions: {
        maxlength: 80
      }
    },
    {
      field: 'parentId',
      type: 'treeSelect',
      label: 'tenant.superior-tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        isSingle: true
      },
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'industry',
      type: 'select',
      label: 'tenant.industry',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('industry')
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ],
    industry: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
    // parentId: [
    //   {
    //     required: true,
    //     message: 'general.required',
    //     trigger: 'blur'
    //   }
    // ]
  }
}
