import {
  getParamsOptionsAsync,
  getCommonParamsOptions
} from '@/utils/common.js'

export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'user.tenant-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'parentId',
      type: 'input',
      label: 'tenant.superior-tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        disabled: true,
        isSingle: true
      },
      placeholder: ' ',
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'industry',
      type: 'select',
      label: 'tenant.industry',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('industry'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200,
        disabled: true
      }
    }
  ],
  validateRules: {}
}
